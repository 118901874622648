<template>
  <div>
    <div class="bannerpng">同鑫独家精准分析，助你更高投资成就</div>
    <div class="w1400">
      <div class="fengleilist clearfix">
        <div class="flistleft">
          <ul >
            <li class="clearfix" @click="vipchose(iteam.id,iteam.page,iteam.subjectId,iteam.marketId)" v-for="(iteam,index) in fxzdarrs" >
              <!-- <img v-if="!fxzdarrs.images" style="width:180px;height: 160px;float: left;margin-right: 10px" src="~assets/img/zwtp.png"/> -->
              <!-- <img v-else style="width:160px;height: 180px;float: left;margin-right: 10px" :src="'http://api.shtx.com.cn:3000/images/thumb/'+gqscarr.images"/> -->
              <img style="width:160px;height: 180px;float: left;margin-right: 10px" :src="iteam.imgPath"/>
              <div class="fxzd_right">
                <h2>{{iteam.title}}</h2>
                <p title="" v-html="iteam.content"></p>
                <div class="fxzd_bottom clearfix">
                  <span>{{iteam.marketName}}</span>
<!--                  <span>{{iteam.productName}}</span>-->
                  <span>{{iteam.date}}</span>
                </div>
              </div>
            </li>
          </ul>
          <Page :total="count"  :page-size="6" @on-change="changenext" show-elevator style="margin:22px 0 180px 0;float: right"></Page>
        </div>
        <div class="flistright">
          <div class="righttop">
            <p>APP扫码下载</p>
            <img src="~assets/img/public/QRcode_app.png" style="width: 150px;height: 150px;margin: 0 auto;display: block"/>
            <span>随时随地 关注行情</span>
          </div>
          <div  class="rightbottom">
            <p>热门分析</p>
            <div @click.stop="handleImgClick($event)">
              <!--<vue-seamless-scroll :data="fgtjarrs" style="height:412px;overflow: hidden;border: 1px solid #E6EBF5;" :class-option="defaultOption">-->
              <ul class="tiaojiabody" style="height:380px;overflow: hidden;border: 1px solid #E6EBF5;">
                <li class="clearfix" v-for="(fgtjarr, index) in fgtjarrs" :id="fgtjarr.id" :data-page="fgtjarr.page" :data-subjectId="fgtjarr.subjectId" :data-marketId="fgtjarr.marketId">
                  <span class="fl"></span>[{{fgtjarr.marketName}}]{{fgtjarr.title}}
                </li>
              </ul>
            <!--</vue-seamless-scroll>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--<Modal-->
      <!--title="提示"-->
      <!--v-model="modal"-->
      <!--:closable="false"-->
      <!--class-name="vertical-center-modal"-->
      <!--width="420"-->
      <!--@on-ok="clickbank"-->
    <!--&gt;-->
      <!--<p>此服务为VIP专属，开通服务请致电 </p>-->
      <!--<p>17717311798</p>-->
    <!--</Modal>-->
  </div>
</template>

<script>
  import NavHead from '@/components/content/mainTabbar/NavHead'
  import LogoBar from '@/components/content/mainTabbar/LogoBar'
  import {getAuctionNewsApi} from 'network/home'
  export default {
    name: 'Analysis',
    data() {
      return {
        content:1,//分页默认第一页
        count:1,//总页数
        flage:0,
        fgtjarrs:[],//热门发布
        fxzdarrs:[],//分析指导
        modal: false,
        token:"",
        fxVip:"",
        userName:""
      }
    },
    computed: {
      defaultOption () {
        return {
          step: 0.6, // 数值越大速度滚动越快
          // limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
          // hoverStop: true, // 是否开启鼠标悬停stop
          // direction: 1, // 0向下 1向上 2向左 3向右
          // openWatch: true, // 开启数据实时监控刷新dom
          // singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
          // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
          // waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
        }
      },
    },
    created () {
      this.token = this.$store.state.token
      this.fxVip=localStorage.getItem('fxVip')
      this.userName=localStorage.getItem('userName')
      this.getAuctionNewsfx()
      this.getAuctionNewsfx_rmfx()
    },
    components: {
      NavHead,
      LogoBar
    },
    methods: {
      changenext(page){
        this.content=page
        this.getAuctionNewsfx()
      },
      clickbank(){

      },
      getAuctionNewsfx(){
        let data = {
          num:this.content,
          size:6,
          productId:14953,
          userName:this.userName
          // name:1
        }
        getAuctionNewsApi(data).then((res) => {
          if(res.code==0){
          this.fxzdarrs=res.data
          this.count=res.count
        }else{
          this.$Message.warning(res.errInfo)
        }
      })
      },
      getAuctionNewsfx_rmfx(){
        let data = {
          num:15,
          size:10,
          productId:14953,
          // name:1
        }
        getAuctionNewsApi(data).then((res) => {
          if(res.code==0){
          this.fgtjarrs=res.data
        }else{
          this.$Message.warning(res.errInfo)
        }
      })
      },
      vipchose(id,page,subjectId,marketId){
        if(this.fxVip&&this.fxVip>0){
          this.$router.push({
            name: 'analysisDel',
            query: {
              id: id,
              page:page,
              subjectId:subjectId,
              marketId:marketId
            },
          })
        }else{
          this.$Modal.warning({
            title: '提示',
            content:
              '<p>此服务为VIP专属，开通服务请致电</p><p>13329574939</p>',
          })
        }
      },
      handleImgClick(e) {
        let id = e.target.id;
        let page = e.target.dataset.page;
        let subjectId = e.target.dataset.subjectid;
        let marketId = e.target.dataset.marketid;
        let data = {
          num:1,
          size:1,
          productId:14953,
          userName:this.userName,
          id:e.target.id
          // name:1
        }
        getAuctionNewsApi(data).then((res) => {
            if(res.data&&res.data.length>0){
              this.$router.push({
                name: 'analysisDel',
                query: {
                  id: id,
                  page:page,
                  subjectId:subjectId,
                  marketId:marketId
                },
              })
            }else{
              this.$Modal.warning({
                title: '提示',
                content:
                  '<p>此服务为VIP专属，开通服务请致电</p><p>13329574939</p>',
              })
            }
         })
      },
    }
  }
</script>

<style scoped>
  body{
    color:#000 !important;
  }
  .bannerpng{
    width: 100%;
    height:180px;
    background: url("~assets/img/1920.png");
    background-color:#3D60BB;
    text-align: center;
    line-height: 180px;
    color: #fff;
    font-size: 40px;
    font-family: MicrosoftYaHei;
  }
  /* .fengleilist{
    margin-bottom: 220px;
  } */
  .flistleft{
    width: 800px;
    /*height: 1207px;*/
    float: left;
    margin-top: 38px;
    border-bottom: 1px solid #E6EBF5;
  }
  .flistleft li{
    padding: 20px 0;
    border-bottom: 1px dashed #E6EBF5;
    cursor: pointer;
  }
  .flistleft .fxzd_right{
    width: 610px;
    float: right;
  }
  .flistleft .fxzd_right h2{
    font-size: 18px;
    margin-bottom: 10px;
  }
  .flistleft .fxzd_right p{
    font-size: 14px;
    line-height: 30px;
    color: #000;
    height: 98px;
    display:-webkit-box;
    -webkit-line-clamp:3;
    -webkit-box-orient:vertical;
    overflow:hidden;
  }
  .flistleft .fxzd_bottom span:nth-child(1){
    float: left;
    width: 70px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    background: #DCEDFF;
    border-radius: 3px;
    color: #25449A;
  }
  .flistleft .fxzd_bottom span:nth-child(2){
      float: right;
    font-size: 14px;
  }
  .flistright{
    width: 350px;
    float: right;
  }
  .flistright .righttop{
    background: #25449A;
    border-radius: 5px;
    margin-top: 38px;
    overflow: hidden;
  }
  .flistright .righttop p{
    text-align: center;
    line-height: 84px;
    font-size: 18px;
    color: #fff;
  }
  .flistright .righttop span{
    display: block;
    font-size: 18px;
    color: #303133;
    line-height: 60px;
    text-align: center;
    background: #D9EBFF;
    margin-top: 30px;
  }
  .flistright .rightbottom{
    margin-top: 23px;
  }
  .flistright .rightbottom p{
    line-height: 40px;
    background: #E6EBF5;
    border-left: 3px solid #25449A;
    font-size: 15px;
    padding-left: 18px;
  }
  .tiaojiabody{
    height: 380px;
    padding: 0px 8px;
    font-size: 14px;
    overflow: hidden;
  }
  .tiaojiabody li{
    line-height: 38px;
    cursor: pointer;
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    list-style: disc;
  }
  .tiaojiabody li:hover{
    color: #25449A;
    text-decoration: underline;
  }
  .tiaojiabody li span.fl:before {
    content: ".";
    width: 4px;
    height: 4px;
    display: inline-block;
    border-radius: 50%;
    background: #000;
    vertical-align: middle;
    margin-right: 15px;
  }
  /*.tubiao{*/
    /*cursor:pointer;*/
    /*display:block;*/
    /*width:22px;*/
    /*height:20px;*/
    /*background: url('~assets/img/tbpng.png');*/
    /*margin: 0 auto;*/
  /*}*/
</style>
<style>
  body{
    color:#000 !important;
  }
  .flistleft .ivu-table th{
    background-color: #E6EBF5;
  }
  .flistleft .ivu-table-stripe .ivu-table-body tr:nth-child(2n) td, .flistleft  .ivu-table-stripe .ivu-table-fixed-body tr:nth-child(2n) td{
    background-color:#F4F6FB
  }
  .vertical-center-modal {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ivu-modal{
    top: 0 !important;
  }
  .vertical-center-modal p{
    text-align: center;
    font-size: 14px;
    line-height: 24px;
  }
  .vertical-center-modal .ivu-modal-header-inner{
    text-align: center;
    font-size: 16px;
  }
</style>
